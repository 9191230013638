import {
  type ClientLoaderFunctionArgs,
  json,
  redirect,
} from '@remix-run/react';

import { writeOrgMasqueradeFallback } from '../components/GameV2/apis/OrgMasqueradeFallback';
import { apiService } from '../services/api-service';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const asOrgId = url.searchParams.get('as-org-id');
  const guestUsers = url.searchParams.get('guest-users');

  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  try {
    const opts = {
      requireAuthentication: true,
      preferRedirect:
        guestUsers === 'enabled' ? ('guest' as const) : ('login' as const),
    };

    await tokenWithRedirect(
      () => apiService.auth.verify(),
      action.request.url,
      opts
    );

    if (asOrgId) {
      // resolve Org
      await tokenWithRedirect(
        () => apiService.organization.getOrganization(asOrgId),
        action.request.url,
        opts
      );

      // write org id so we know who to masquerade as on subsequent pages
      writeOrgMasqueradeFallback(asOrgId);
    }

    // redirect to the overworld
    const destination = new URL(action.request.url);
    destination.pathname = `/game-packs/${id}/overworld`;
    destination.searchParams.delete('guest-users');
    destination.searchParams.delete('as-org-id');
    throw redirect(destination.toString());
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }
};
